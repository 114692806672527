//
// Theme Docs
//

// Docs header




// Sidebar
.sidebar-nav-fixed {
  position: sticky;
  top: 4rem;
  height: calc(100vh - 2rem);
  overflow-y: auto;
  padding-top: 4.0625rem;

  ul {
    list-style: none;
    li {
      a {
        font-size: 0.8125rem;
        color: var(--#{$prefix}gray-500);
        display: block;
        padding: 0.125rem 1.5rem;
        background: transparent;
        border-radius: 0px;
        border-left: 2px solid transparent;
        line-height: 1.4375rem;
        text-decoration: none;
        transition: 0.5s ease;
        &:hover {
          color: var(--#{$prefix}primary);
        }
      }
      a.active {
        display: block;
        color: var(--#{$prefix}primary);
        background: transparent !important;
        text-decoration: none;
        border-left-color: var(--#{$prefix}primary);
        transition: 0.5s ease;
      }
      ul {
        list-style: none;
        padding-left: 0rem;
        li {
          a {
            padding: 0.125rem 2.5rem;
          }
        }
      }
    }
  }
}



// layouts

// Docs Main wrapper
.docs-main-wrapper {
  min-height: 100%;
  position: relative;
}


// Docs wrapper
.docs-wrapper {
  position: relative;
  left: 0;
  margin-left: 15.625rem;
  top: 35px;
}
.docs-content {
  padding: 4rem;
  @media only screen and (max-width: 820px) {
    padding: 1.5rem;
  }
}
.docs-content-head {
  padding: 4.375rem 0rem;
}


// Static modal
.gd-example-modal.modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}



// Scrollspy
.scrollspy-example {
  position: relative;
  height: 12.5rem;
  margin-top: 0.5rem;
  overflow: auto;
}
.scrollspy-example-2 {
  position: relative;
  height: 21.875rem;
  overflow: auto;
}


// Toast
.toast.hide {
  display: none;
}


// Color Swatch
.color-swatch {
  margin-bottom: 1.875rem;
}
.color-swatch-header {
  border-radius: 0.5rem;
  height: 4.5rem;
}
.color-swatch-body {
  margin-top: 0.875rem;
  line-height: 1;
}
.color-swatch-body-label {
  margin-bottom: 0.25rem;
}
.color-swatch-body-value {
  margin-bottom: 0rem;
}


// Docs border utils
.docs-example-border-utils {
  [class^="border"] {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: 0.25rem;
    background-color: var(--#{$prefix}gray-100);
  }
}
.docs-example-border-utils-0 {
  [class^="border"] {
    border: 1px solid var(--#{$prefix}gray-300);
  }
}
:focus {
  outline: 0;
}





// ratio
.ratio {
  display: inline-block;
  width: 10rem;
  color: var(--#{$prefix}gray-600);
  background-color: var(--#{$prefix}gray-100);
  border: 1px solid var(--#{$prefix}gray-300);
}



// docs nav
.docs-content {
  .nav-line-bottom {
    .nav-item {
      .nav-link {
        padding: 0.75rem;
      }
    }
  }
}
