//
// Extended from bootstrap
//
// Form

.form-control-flush {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-left: 0px;
  border-radius: 0px;

  &:focus {
    color: #495057;
    background-color: #fff;
    border-bottom-color: var(--#{$prefix}gray-400);
    outline: 0;
    box-shadow: none;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



// input spinner
.input-spinner{
  width: 110px;
  .button-minus, .button-plus{
    height: 2rem;
    width:1.8rem;
    border-color: var(--#{$prefix}gray-300);
    background-color: transparent;
    color: var(--#{$prefix}gray-500);
    &:hover{
      background-color: var(--#{$prefix}gray-300);
      border-color: var(--#{$prefix}gray-300);
  
    }
    
  }
  .form-input{
    width: 2rem !important;
  
      height: 100%;
      min-height: 2rem;
      text-align: center;
      border: 1px solid var(--#{$prefix}gray-300);
      background: 0 0;
      color: var(--#{$prefix}gray-500);
  }

}


// file upload
.file-upload {
  position: relative;
  overflow: hidden;
  margin: 10px;

  input.file-input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity = 0);
    height: 100%;
  }
}



// form check
.form-check-line-through + label {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.form-check-line-through:checked + label {
  color: var(--#{$prefix}gray-400);
  text-decoration: line-through;
}

