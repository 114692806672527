// Mail scss


// navbar mail
.navbar-mail {
    .navbar-nav {
        .nav-item {
            margin-bottom: 0.25rem;

            .nav-link {
                color: var(--#{$prefix}gray-400);
                padding: 0.5rem 0.75rem;
                font-weight: $font-weight-medium;

                &:hover,
                &.active {
                    background-color: var(--#{$prefix}gray-100);
                    color: var(--#{$prefix}gray-500);
                    border-radius: $border-radius;
                }
            }
        }
    }
}



// list group
.list-group-mail {
    .list-mail {
        color: var(--#{$prefix}gray-700);

        &:hover,
        &:focus,
        &.active {
            box-shadow: $box-shadow-lg;
            background-color: var(--#{$prefix}gray-100);
            border-color: var(--#{$prefix}gray-100);
            color: var(--#{$prefix}gray-700);

            .actions-mail {
                opacity: 1;
            }
        }

        .list-title {
            max-width: 168px;
            -webkit-flex-basis: 168px;
            flex-basis: 168px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 1rem;

            @media (max-width: 768px) {
                max-width: 88px;
                -webkit-flex-basis: 88px;
                flex-basis: 88px;
            }

            a {
                color: var(--#{$prefix}gray-700);
            }
        }

        .list-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1 1 auto;
            color: var(--#{$prefix}gray-700);
        }

        .list-time {
            position: absolute;
            right: 0;
            margin-right: 1.5rem;
        }
    }
}


// actions mail

.actions-mail {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--#{$prefix}white);
    margin: 8px;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem;
    line-height: 1;
    box-shadow: $box-shadow-sm;

    a {
        color: var(--#{$prefix}gray-500);
        margin-right: 0.25rem;
        font-size: 16px;
        font-weight: 500;

        &:hover {
            color: var(--#{$prefix}primary);
        }
    }
}

// compose mail
.compose-mail {
    .ql-toolbar.ql-snow {
        border: none;
        border-bottom: 1px solid var(--#{$prefix}gray-300);
        border-radius: 0px;
    }

    .ql-container.ql-snow {
        border: none;
    }

    .ql-editor {
        height: 250px;
    }

    .compose-img-upload {
        cursor: pointer;

        &>input {
            display: none;
        }

        label {
            cursor: pointer;
        }
    }
}