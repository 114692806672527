// Extend from bootstrap

// Progress bar

.progress-tooltip {
  overflow: visible;
  .progress-bar {
    overflow: visible;
    position: relative;
    border-radius: 0.5rem;
    span {
      position: absolute;
      top: -20px;
      font-size: 13px;
      line-height: 10px;
      padding: 2px 3px 2px 4px;
      right: -1.4em;
      border-radius: 2px;
      color: var(--#{$prefix}dark);
      font-weight: $font-weight-medium;
    }
  }
}


// progress bar for step

.progress-container {
	width: 100%;
	margin: 2em auto;
}
.progress-steps {
	counter-reset: step;
	li {
		list-style-type: none;
		width: 25%;
		float: left;
		position: relative;
		text-align: center;
		color: var(--#{$prefix}body-color);
		&:before {
			width: 14px;
			height: 14px;
			content: "";
			counter-increment: step;
			line-height: 2.5em;
			display: block;
			text-align: center;
			margin: 0 auto 0.7em auto;
			border-radius: 50%;
			background-color: var(--#{$prefix}gray-300);
			border: 2px solid var(--#{$prefix}light);
		}
		&:after {
			width: 100%;
			height: 3px;
			content: "";
			position: absolute;
			background-color: var(--#{$prefix}gray-300);
			top: 0.4em;
			left: -50%;
			z-index: -1;
		}
		&:first-child {
			&:after {
				content: none;
			}
		}
		&.active {
			color:var(--#{$prefix}primary);
			&:before {
				border-color: var(--#{$prefix}light);
				background-color:var(--#{$prefix}primary);
			}
			& + li {
				&:after {
					background-color:var(--#{$prefix}primary);
				}
			}
		}
	}
}
