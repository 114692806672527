
// Extend from bootstrap
// Reboot


body{
    -webkit-font-smoothing: antialiased;

}

a {
    text-decoration: none !important;
}