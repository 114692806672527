// Apex Chart


.apexcharts-tooltip {
    border-radius: 0.5rem !important;
    box-shadow: $box-shadow-sm !important;
    .apexcharts-tooltip-series-group {
        background: var(--#{$prefix}white) !important;
        color: var(--#{$prefix}dark);
        padding: 3px 16px 6px 16px !important;
        display: none;
        text-align: left;
        justify-content: left;
        align-items: center;
        line-height: 1 !important;
    }
}
.apexcharts-tooltip-marker {
    width: 8px !important;
    height: 8px !important;
    position: relative;
    top: 0px;
    margin-right: 6px !important;
    border-radius: 50%;
}
.apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid var(--#{$prefix}gray-300) !important;
    background-color: var(--#{$prefix}white) !important;
    background: var(--#{$prefix}white) !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: var(--#{$prefix}white) !important;
    border-bottom: 1px solid var(--#{$prefix}gray-300) !important;
}
.apexcharts-tooltip-title {
    padding: 8px 16px !important;
    font-size: 14px !important;
    margin-bottom: 4px;
    font-weight: 600 !important;
}
.apexcharts-xaxis-label {
    fill: var(--#{$prefix}gray-500);
    font-size: 13px;
}
.apexcharts-yaxis-label {
    fill: var(--#{$prefix}gray-500);
    font-size: 13px;
}
.apexcharts-legend-text{
    color: var(--#{$prefix}gray-500) !important;

}