/*
=========================================================
* Dash UI - Bootstrap 5 Admin & Dashboard Theme
=========================================================
* Product Page: https://codescandy.com/dashui/index.html
* Copyright 2020 Codescandy (https://codescandy.com/)
* Designed and coded by https://codescandy.com
========================================================= */

// Inter Fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Bootstrap functions
@import "../../../node_modules/bootstrap/scss/functions";

// User Variables
@import "user-variables";

// theme variables
@import "theme/variables";

//utilities
@import "theme/utilities";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Root
@import "theme/root";

// Theme
@import "theme/theme";

// User
@import "user";
