// Docs Sidebar

@media (max-width: 768px) {
  // Docs nav sidebar
  .docs-nav-sidebar {
    .navbar-toggler {
      background-color: var(--#{$prefix}white);
    }
    width: 100%;
    position: relative;
    right: 0rem;
    top: 0;
  }
}

 
.doc-sidebar {
  z-index: 2;
  width: 13.625rem;
  border-right: 1px solid var(--#{$prefix}border-color);
  position: fixed;
  height: 100%;
  overflow: auto;
  @media (max-width: 990px) {
    width: 100%;
    position: relative;
    border: 0;
    height: auto;
  }
  @media (min-width: 990px) and (max-width: 1200px) {
    width: 10rem;
  }
  .navbar-nav {
    padding-top: 1rem;
    .navbar-header {
      margin-bottom: 0.5rem;
      padding: 0rem 1rem;
      margin-top: 2rem;
      font-size: 0.75rem;
      .heading {
        font-size: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        color: var(--#{$prefix}gray-400);
        margin-bottom: 0rem;
      }
      span {
        color: var(--#{$prefix}gray-600);
      }
    }
    .nav-item {
      .nav-link {
        color: var(--#{$prefix}body-color);
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: 0.25rem 1rem;
        font-family: $font-family-sans-serif;
        font-weight: 500;

        &:hover {
          color: var(--#{$prefix}primary);
        }
      }
      .nav-link.active {
        color: var(--#{$prefix}primary);
      }
    }
  }
}
